import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, distinctUntilChanged, filter, map, Observable, switchMap, tap } from 'rxjs';

import { isNotNil, KeywordSearchImpressionsType, TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { TopKeywordSearchImpressionsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import { TopKeywordSearchModalComponent } from ':modules/statistics/seo/keyword-search-impressions/top-keyword-search-impressions/top-keyword-search-modal/top-keyword-search-modal.component';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CreateIndexArrayPipe } from ':shared/pipes/create-array.pipe';
import { Emoji, EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface KeywordsToDisplayCountByType {
    [KeywordSearchImpressionsType.BRANDING]: number;
    [KeywordSearchImpressionsType.DISCOVERY]: number;
}

@Component({
    selector: 'app-top-keyword-search-impressions',
    standalone: true,
    imports: [CreateIndexArrayPipe, TranslateModule, ApplyPurePipe, ShortTextPipe, MatTooltip, NgTemplateOutlet],
    templateUrl: './top-keyword-search-impressions.component.html',
    styleUrl: './top-keyword-search-impressions.component.scss',
    providers: [EmojiPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopKeywordSearchImpressionsComponent implements OnInit {
    private readonly _keywordSearchImpressionsService = inject(KeywordSearchImpressionsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);
    private readonly _emojiPathResolverPipe = inject(EmojiPathResolverPipe);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _destroyRef = inject(DestroyRef);

    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;
    private readonly _KEYWORDS_TO_DISPLAY_LIMIT = 3;
    readonly SHORT_TEXT_LENGTH = 35;

    private readonly _dates$: Observable<DatesAndPeriod> = this._store
        .select(StatisticsSelector.selectDatesFilter)
        .pipe(distinctUntilChanged());

    readonly topKeywordSearchImpressions: WritableSignal<TopKeywordSearchImpressionsData> = signal({
        [KeywordSearchImpressionsType.BRANDING]: [],
        [KeywordSearchImpressionsType.DISCOVERY]: [],
    });
    readonly isLoading: WritableSignal<boolean> = signal(true);
    readonly hasData: WritableSignal<boolean> = signal(true);
    readonly keywordsToDisplayCountByType = signal({
        [KeywordSearchImpressionsType.BRANDING]: this._KEYWORDS_TO_DISPLAY_LIMIT,
        [KeywordSearchImpressionsType.DISCOVERY]: this._KEYWORDS_TO_DISPLAY_LIMIT,
    });

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this._dates$])
            .pipe(
                filter(([restaurant, dates]) => isNotNil(restaurant) && !!dates.startDate && !!dates.endDate),
                map(([restaurant, dates]) => [restaurant, dates.startDate, dates.endDate]),
                tap(() => this._reset()),
                switchMap(([restaurant, startDate, endDate]: [Restaurant, Date, Date]) =>
                    this._keywordSearchImpressionsService.getTopKeywordSearchImpressions({
                        restaurantId: restaurant._id,
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                        limit: TOP_KEYWORD_SEARCH_IMPRESSIONS_LIMIT,
                    })
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (topKeywordSearchImpressions) => {
                    this.isLoading.set(false);
                    this.topKeywordSearchImpressions.set(topKeywordSearchImpressions);
                    this.keywordsToDisplayCountByType.set(this._getKeywordsToDisplayCountByType(topKeywordSearchImpressions));
                },
                error: (error) => {
                    this.isLoading.set(false);
                    console.warn('error :>>', error);
                },
            });
    }

    openTopKeywordSearchModal(type: KeywordSearchImpressionsType): void {
        this._customDialogService
            .open(TopKeywordSearchModalComponent, {
                width: '40vw',
                data: {
                    topKeywordSearchImpressions: this.topKeywordSearchImpressions(),
                    type,
                },
            })
            .afterClosed();
    }

    getEmojiFromPosition = (position: number): string =>
        [
            this._emojiPathResolverPipe.transform(Emoji.TROPHY),
            this._emojiPathResolverPipe.transform(Emoji.SECOND_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.THIRD_PLACE_MEDAL),
            this._emojiPathResolverPipe.transform(Emoji.PARTY_FACE),
        ][position] ?? this._emojiPathResolverPipe.transform(Emoji.HAPPY_FACE);

    private _getKeywordsToDisplayCountByType(topKeywordSearchImpressions: TopKeywordSearchImpressionsData): KeywordsToDisplayCountByType {
        const brandingKeywordsCount = topKeywordSearchImpressions[KeywordSearchImpressionsType.BRANDING].length;
        const discoveryKeywordsCount = topKeywordSearchImpressions[KeywordSearchImpressionsType.DISCOVERY].length;

        return {
            [KeywordSearchImpressionsType.BRANDING]: Math.max(
                this._KEYWORDS_TO_DISPLAY_LIMIT,
                this._KEYWORDS_TO_DISPLAY_LIMIT * 2 - discoveryKeywordsCount
            ),
            [KeywordSearchImpressionsType.DISCOVERY]: Math.max(
                this._KEYWORDS_TO_DISPLAY_LIMIT,
                this._KEYWORDS_TO_DISPLAY_LIMIT * 2 - brandingKeywordsCount
            ),
        };
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.hasData.set(false);
    }
}

import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { PlatformKey, PostFeedbacks, PostPublicationStatus } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { FeedComponent } from ':modules/posts-v2/social-posts/components/feed/feed.component';
import { NotesComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/notes/notes.component';
import { PreviewsComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/previews/previews.component';
import { SocialPostCaptionAiGenerationComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/social-post-caption-ai-generation/social-post-caption-ai-generation.component';
import { SocialPostChooseHashtagsPanelComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/social-post-choose-hashtags-panel/social-post-choose-hashtags-panel.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { IUpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { User } from ':modules/user/user';
import { DefaultPrompt } from ':shared/openai-prompt/openai-prompt.component';
import { Illustration } from ':shared/pipes/illustration-path-resolver.pipe';

interface Tab {
    label: string;
    key: TabKeys;
}

enum TabKeys {
    PREVIEWS = 'previews',
    FEED = 'feed',
    NOTES = 'notes',
}

@Component({
    selector: 'app-previews-feed-notes',
    templateUrl: './previews-feed-notes.component.html',
    styleUrls: ['./previews-feed-notes.component.scss'],
    standalone: true,
    imports: [
        MatTabsModule,
        NotesComponent,
        FeedComponent,
        PreviewsComponent,
        SocialPostCaptionAiGenerationComponent,
        SocialPostChooseHashtagsPanelComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewsFeedNotesComponent {
    readonly shouldOpenFeedbacks = input<boolean>(false);

    readonly upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);
    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);

    readonly defaultPrompt = signal(DefaultPrompt.GENERATE_POST);

    private readonly _PREVIEWS_TAB: Tab = {
        key: TabKeys.PREVIEWS,
        label: this._translateService.instant('social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.title'),
    };
    private readonly _FEED_TAB: Tab = {
        key: TabKeys.FEED,
        label: this._translateService.instant('social_posts.upsert_social_post_modal.previews_feed_notes.tabs.feed.title'),
    };
    private readonly _NOTES_TAB: Tab = {
        key: TabKeys.NOTES,
        label: this._translateService.instant('social_posts.upsert_social_post_modal.previews_feed_notes.tabs.notes.title'),
    };
    readonly TABS = [this._PREVIEWS_TAB, this._FEED_TAB, this._NOTES_TAB];
    readonly selectedTabIndex = signal<number>(0);
    readonly selectedTab = computed<Tab>(() => this.TABS[this.selectedTabIndex()]);

    // Feed
    readonly feedWithCurrentPost = computed(() => {
        const feed = this._socialPostsContext.feed();
        const currentPost = this._upsertSocialPostContext.upsertSocialPostState.post();

        if (!currentPost.platformKeys.includes(PlatformKey.INSTAGRAM)) {
            return feed;
        }
        const isCurrentPostInFeed = feed.some((feedItem) => feedItem.postId === currentPost.id);
        if (isCurrentPostInFeed) {
            return feed;
        }
        const currentPostAsFeedItem = this._getFeedItemFromPost(currentPost);
        return [...feed, currentPostAsFeedItem].sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
    });
    readonly isFetchingFeed = this._socialPostsContext.isFetchingFeed;
    readonly isFetchingMoreFeed = this._socialPostsContext.isFetchingMoreFeed;

    // Feedbacks
    private readonly _restaurantManagers$ = this._restaurantsService.restaurantSelected$.pipe(
        map((restaurant) => restaurant?.managers.map((restaurantUser) => new User(restaurantUser.user)) ?? []),
        takeUntilDestroyed()
    );
    readonly restaurantManagers = toSignal<User[]>(this._restaurantManagers$);

    readonly feedbacks = this._upsertSocialPostContext.upsertSocialPostState.post.feedbacks;

    readonly postId = this._upsertSocialPostContext.upsertSocialPostState.post.id;
    readonly isPostPublished = computed(
        (): boolean => this._upsertSocialPostContext.upsertSocialPostState.post.published() === PostPublicationStatus.PUBLISHED
    );

    readonly selectedPlatformKeys = this._upsertSocialPostContext.upsertSocialPostState.post.platformKeys;

    readonly Illustration = Illustration;
    readonly TabKeys = TabKeys;

    constructor() {
        effect(
            () => {
                if (this.shouldOpenFeedbacks()) {
                    const notesTabIndex = this.TABS.findIndex((tab) => tab.key === TabKeys.NOTES);
                    this.selectedTabIndex.set(Math.max(notesTabIndex, 0));
                }
            },
            { allowSignalWrites: true }
        );
    }

    handleTabChange(index: number): void {
        this.selectedTabIndex.set(index);
    }

    onFeedbacksChange(feedbacks: null | PostFeedbacks): void {
        this._upsertSocialPostContext.updateFeedbacks(feedbacks);
    }

    private _getFeedItemFromPost(post: IUpsertSocialPost): FeedItem {
        return new FeedItem({
            postId: post.id,
            media: this._getSocialPostMediaFromAttachments(post),
            published: post.published,
            postType: post.postType,
            plannedPublicationDate: post.plannedPublicationDate,
            socialCreatedAt: post.socialCreatedAt,
            updatedAt: new Date(),
        });
    }

    private _getSocialPostMediaFromAttachments(post: IUpsertSocialPost): SocialPostMedia | null {
        const firstAttachment = post.attachments[0];
        if (!firstAttachment) {
            return null;
        }

        return new SocialPostMedia({
            url: firstAttachment.thumbnail256OutsideUrl,
            type: firstAttachment.type,
            thumbnailUrl: firstAttachment.thumbnail256OutsideUrl,
            thumbnailDimensions: firstAttachment.thumbnail256OutsideDimensions,
            transformData: firstAttachment.transformData,
        });
    }
}

<div class="rounded-[5px] border border-malou-color-border-primary bg-white">
    <div class="flex items-center gap-x-2 px-3 py-4">
        @if (profilePicture(); as profilePicture) {
            <div class="flex h-[29px] w-[29px] items-center justify-center rounded-full bg-malou-color-gradient-instagram">
                <img class="h-[25px] w-[25px] rounded-full" [src]="profilePicture" />
            </div>
        }
        <div class="font-[Helvetica] text-[13px] font-bold text-malou-color-text-1">{{ usernameWithoutAt() }}</div>
    </div>

    <div class="relative">
        @if (medias().length > 0) {
            <div class="flex overflow-hidden" #slider>
                @for (media of medias(); let index = $index; track media.id) {
                    <img
                        class="min-w-full"
                        [id]="IMG_ID_PREFIX + index"
                        [ngStyle]="aspectRatioStyle()"
                        [src]="media.thumbnail1024OutsideUrl" />
                }
            </div>
            @if (isCarousel()) {
                @if (currentMediaIndex() > 0) {
                    <div
                        class="absolute left-4 top-1/2 flex h-6 w-6 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white opacity-80"
                        (click)="previousMedia()">
                        <mat-icon class="!h-[14px] !w-[14px]" color="transparent" [svgIcon]="SvgIcon.CHEVRON_LEFT"></mat-icon>
                    </div>
                }
                @if (currentMediaIndex() < medias().length - 1) {
                    <div
                        class="absolute right-4 top-1/2 flex h-6 w-6 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white opacity-80"
                        (click)="nextMedia()">
                        <mat-icon class="!h-[14px] !w-[14px]" color="transparent" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                    </div>
                }
                <div class="absolute bottom-4 left-1/2 flex -translate-x-1/2 gap-1">
                    @for (media of medias(); let index = $index; track index) {
                        <div
                            class="h-[6px] w-[6px] rounded-full bg-white"
                            [ngClass]="{ 'opacity-40': index !== currentMediaIndex() }"></div>
                    }
                </div>
            }
        } @else {
            <img class="min-w-full" [id]="IMG_ID_PREFIX + 0" [ngStyle]="aspectRatioStyle()" [src]="'default_post' | imagePathResolver" />
        }
    </div>

    <div class="flex flex-col gap-6 px-3 py-4 font-[Helvetica] text-[13px] text-malou-color-text-1">
        <div class="flex justify-between">
            <div class="flex gap-x-3">
                <img [src]="'like' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
                <img [src]="'comment' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
                <img [src]="'send' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            </div>
            <div>
                <img [src]="'save' | imagePathResolver: { folder: 'social-post-previews/instagram', extensionFormat: 'svg' }" />
            </div>
        </div>

        <div class="flex flex-col gap-[1px]">
            <div class="font-bold">
                {{ 'social_posts.upsert_social_post_modal.previews_feed_notes.tabs.previews.instagram.likes' | translate }}
            </div>
            <div>
                <span class="mr-3 font-bold">{{ usernameWithoutAt() }}</span>
                <span class="font-normal" [innerHTML]="formattedText() | htmlTag"></span>
            </div>
        </div>
    </div>
</div>

import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { KeywordSearchImpressionsInsightsDto } from '@malou-io/package-dto';
import {
    getMonthAndYearBetweenDates,
    isNotNil,
    KeywordSearchImpressionsType,
    MalouComparisonPeriod,
    MonthAndYear,
} from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ImpressionsInsightsChartComponent } from ':modules/statistics/seo/keyword-search-impressions/impressions-insights/impressions-insights-chart/impressions-insights-chart.component';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { KeywordSearchImpressionsService } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.service';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface ImpressionsInsightsEvolutionData {
    [KeywordSearchImpressionsType.BRANDING]: {
        total: number;
        evolution: number;
    };
    [KeywordSearchImpressionsType.DISCOVERY]: {
        total: number;
        evolution: number;
    };
}

@Component({
    selector: 'app-impressions-insights',
    standalone: true,
    imports: [
        ImpressionsInsightsChartComponent,
        SkeletonComponent,
        NgTemplateOutlet,
        NumberEvolutionComponent,
        ShortNumberPipe,
        TranslateModule,
    ],
    templateUrl: './impressions-insights.component.html',
    styleUrl: './impressions-insights.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribeOnDestroy()
export class ImpressionsInsightsComponent implements OnInit, KillSubscriptions {
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    private readonly _keywordSearchImpressionsService = inject(KeywordSearchImpressionsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _store = inject(Store);

    readonly impressionsInsightsData: WritableSignal<ImpressionsInsightsData[]> = signal([]);
    readonly impressionsInsightsEvolutionData: WritableSignal<ImpressionsInsightsEvolutionData> = signal({
        [KeywordSearchImpressionsType.BRANDING]: { total: 0, evolution: 0 },
        [KeywordSearchImpressionsType.DISCOVERY]: { total: 0, evolution: 0 },
    });

    private readonly _dates$: Observable<DatesAndPeriod> = this._store
        .select(StatisticsSelector.selectDatesFilter)
        .pipe(distinctUntilChanged());

    readonly KeywordSearchImpressionsType = KeywordSearchImpressionsType;

    readonly isLoading: WritableSignal<boolean> = signal(true);
    readonly hasData: WritableSignal<boolean> = signal(true);

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this._dates$])
            .pipe(
                filter(([restaurant, dates]) => isNotNil(restaurant) && !!dates.startDate && !!dates.endDate),
                map(([restaurant, dates]) => [restaurant, dates.startDate, dates.endDate]),
                tap(() => this._reset()),
                switchMap(([restaurant, startDate, endDate]: [Restaurant, Date, Date]) =>
                    forkJoin([
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights({
                            restaurantId: restaurant._id,
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                        }),
                        this._keywordSearchImpressionsService.getKeywordSearchImpressionsInsights({
                            restaurantId: restaurant._id,
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                            comparisonPeriod: MalouComparisonPeriod.PREVIOUS_PERIOD,
                        }),
                        of({ startDate, endDate }),
                    ])
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ([currentPeriodData, previousPeriodData, { startDate, endDate }]) => {
                    this.isLoading.set(false);
                    this.impressionsInsightsData.set(
                        this._getImpressionsInsightsData({
                            currentImpressionsData: currentPeriodData,
                            startDate,
                            endDate,
                        })
                    );
                    this.impressionsInsightsEvolutionData.set(
                        this._getImpressionsInsightsEvolutionData(currentPeriodData, previousPeriodData)
                    );
                },
                error: (error) => {
                    this.isLoading.set(false);
                    console.warn('error :>>', error);
                },
            });
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.hasData.set(true);
    }

    private _getImpressionsInsightsData({
        currentImpressionsData,
        startDate,
        endDate,
    }: {
        currentImpressionsData: KeywordSearchImpressionsInsightsDto[];
        startDate: Date;
        endDate: Date;
    }): ImpressionsInsightsData[] {
        const monthAndYearRange: MonthAndYear[] = getMonthAndYearBetweenDates(startDate, endDate);

        return monthAndYearRange.map((monthAndYear) => {
            const monthInsights = currentImpressionsData.find(
                (insight) => insight.month === monthAndYear.month && insight.year === monthAndYear.year
            );
            return {
                month: monthAndYear.month,
                year: monthAndYear.year,
                [KeywordSearchImpressionsType.BRANDING]: monthInsights?.[KeywordSearchImpressionsType.BRANDING] ?? 0,
                [KeywordSearchImpressionsType.DISCOVERY]: monthInsights?.[KeywordSearchImpressionsType.DISCOVERY] ?? 0,
            };
        });
    }

    private _getImpressionsInsightsEvolutionData(
        currentImpressionsData: KeywordSearchImpressionsInsightsDto[],
        previousImpressionsData: KeywordSearchImpressionsInsightsDto[]
    ): ImpressionsInsightsEvolutionData {
        const _reduceFun = (
            acc,
            current
        ): { [KeywordSearchImpressionsType.BRANDING]: number; [KeywordSearchImpressionsType.DISCOVERY]: number } => {
            acc[KeywordSearchImpressionsType.BRANDING] += current[KeywordSearchImpressionsType.BRANDING];
            acc[KeywordSearchImpressionsType.DISCOVERY] += current[KeywordSearchImpressionsType.DISCOVERY];
            return acc;
        };

        const total = currentImpressionsData.reduce(_reduceFun, {
            [KeywordSearchImpressionsType.BRANDING]: 0,
            [KeywordSearchImpressionsType.DISCOVERY]: 0,
        });

        const previousTotal = previousImpressionsData.reduce(_reduceFun, {
            [KeywordSearchImpressionsType.BRANDING]: 0,
            [KeywordSearchImpressionsType.DISCOVERY]: 0,
        });

        return {
            [KeywordSearchImpressionsType.BRANDING]: {
                total: total[KeywordSearchImpressionsType.BRANDING],
                evolution: total[KeywordSearchImpressionsType.BRANDING] - previousTotal[KeywordSearchImpressionsType.BRANDING],
            },
            [KeywordSearchImpressionsType.DISCOVERY]: {
                total: total[KeywordSearchImpressionsType.DISCOVERY],
                evolution: total[KeywordSearchImpressionsType.DISCOVERY] - previousTotal[KeywordSearchImpressionsType.DISCOVERY],
            },
        };
    }
}

@if (!isLoading()) {
    @if (hasData()) {
        <div class="flex flex-col gap-4">
            <div class="malou-text-18--bold malou-color-text-1">
                {{ 'statistics.seo.keyword_search_impressions.insights.title' | translate }}
            </div>
            <app-impressions-insights-chart [impressionsInsightsData]="impressionsInsightsData()"></app-impressions-insights-chart>
            <ng-container [ngTemplateOutlet]="evolutionTemplate"></ng-container>
        </div>
    }
} @else {
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
}

<ng-template #loadingTemplate>
    <div class="min-w-0 flex-1">
        <app-skeleton skeletonClass="!h-[400px] secondary-bg w-full"></app-skeleton>
    </div>
</ng-template>

<ng-template #evolutionTemplate>
    <div class="flex gap-4 md:flex-col">
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ impressionsInsightsEvolutionData().branding.total | shortNumber: { shouldDisplayMinusSign: false } }}
                </div>
                <app-number-evolution
                    [value]="impressionsInsightsEvolutionData().branding.evolution"
                    [displayedValue]="
                        impressionsInsightsEvolutionData().branding.evolution | shortNumber: { shouldDisplayMinusSign: false }
                    ">
                </app-number-evolution>
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.seo.keyword_search_impressions.branding' | translate }}
            </div>
        </div>
        <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ impressionsInsightsEvolutionData().discovery.total | shortNumber: { shouldDisplayMinusSign: false } }}
                </div>
                <app-number-evolution
                    [value]="impressionsInsightsEvolutionData().discovery.evolution"
                    [displayedValue]="impressionsInsightsEvolutionData().discovery.total | shortNumber: { shouldDisplayMinusSign: false }">
                </app-number-evolution>
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'statistics.seo.keyword_search_impressions.discovery' | translate }}
            </div>
        </div>
    </div>
</ng-template>

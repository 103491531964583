import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { DateTime } from 'luxon';
import { NgChartsModule } from 'ng2-charts';

import { APP_DEFAULT_LANGUAGE, KeywordSearchImpressionsType } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';
import { ImpressionsInsightsData } from ':modules/statistics/seo/keyword-search-impressions/keyword-search-impressions.interface';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { ChartDataArray, malouChartColorBluePurple, malouChartColorLighterBlue, malouChartColorLightPurple } from ':shared/helpers';

type BarChartType = Extract<ChartType, 'bar'>;

@Component({
    selector: 'app-impressions-insights-chart',
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    templateUrl: './impressions-insights-chart.component.html',
    styleUrl: './impressions-insights-chart.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpressionsInsightsChartComponent {
    readonly impressionsInsightsData = input.required<ImpressionsInsightsData[]>();

    private readonly _translateService = inject(TranslateService);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly chartData: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() =>
        this._computeChartData(this.impressionsInsightsData())
    );
    readonly chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.impressionsInsightsData()));
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions(this.chartLabels()));

    private _computeChartData(impressionsInisghtsData: ImpressionsInsightsData[]): ChartDataset<BarChartType, ChartDataArray>[] {
        const brandingValues = impressionsInisghtsData.map((data) => data[KeywordSearchImpressionsType.BRANDING]);
        const discoveryValues = impressionsInisghtsData.map((data) => data[KeywordSearchImpressionsType.DISCOVERY]);

        return [
            {
                label: this._translateService.instant('statistics.seo.keyword_search_impressions.branding'),
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: brandingValues,
            },
            {
                label: this._translateService.instant('statistics.seo.keyword_search_impressions.discovery'),
                borderColor: malouChartColorLightPurple,
                backgroundColor: malouChartColorLightPurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                barThickness: 7,
                data: discoveryValues,
            },
        ];
    }

    private _computeChartLabels(impressionsInisghtsData: ImpressionsInsightsData[]): string[] {
        const lang = LocalStorage.getItem(LocalStorageKey.LANG) ?? APP_DEFAULT_LANGUAGE;
        return impressionsInisghtsData.map((data) =>
            DateTime.fromObject({ month: data.month, year: data.year }).setLocale(lang).toFormat('LLL yyyy')
        );
    }

    private _computeChartOptions(labels: string[]): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_, index: number, _ticks: Tick[]): string => labels[index],
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                        display: true,
                        color: (_context): string | undefined => malouChartColorLighterBlue,
                    },
                    position: 'left',
                },
            },
        };
    }

    private _computeTooltipLabel(tooltipItem: TooltipItem<any>): string {
        return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
    }
}

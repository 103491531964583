<div
    class="flex items-center border-b border-malou-color-border-primary bg-malou-color-background-white px-6 py-2"
    [ngClass]="{ 'justify-between': !isSelecting(), 'justify-end': isSelecting() }">
    @if (isSelecting()) {
        <div class="flex h-[50px] items-center gap-x-2">
            @if (atLeastOnePostInList()) {
                <button class="malou-btn-flat" mat-button (click)="setIsSelecting(false)">
                    {{ 'common.cancel' | translate }}
                </button>

                <button
                    class="malou-btn-icon--secondary"
                    mat-icon-button
                    [disabled]="!selectedPosts().length"
                    (click)="onDuplicateSelection()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                </button>

                <button
                    class="malou-btn-icon--secondary"
                    mat-icon-button
                    [disabled]="!selectedPosts().length"
                    (click)="onDeleteSelection()">
                    <mat-icon color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
            }
        </div>
    } @else {
        <div class="flex items-center gap-x-1">
            <button
                class="malou-btn-icon--secondary !border-none"
                data-testid="social-post-sync-btn"
                mat-icon-button
                [matTooltip]="'common.synchronize' | translate"
                [disabled]="!isAnyPlatformConnected()"
                (click)="synchronize()">
                <mat-icon [svgIcon]="SvgIcon.SYNC"></mat-icon>
            </button>

            @for (filterOptionAndCount of filterOptionsAndCount(); track filterOptionAndCount.filterOption) {
                @let filterOption = filterOptionAndCount.filterOption;
                @let count = filterOptionAndCount.count;
                <div
                    class="flex cursor-pointer gap-x-[2px] rounded p-2 text-malou-color-text-1"
                    [ngClass]="{
                        'malou-text-12--regular': filterOption !== selectedFilter(),
                        'malou-text-12--semibold bg-malou-color-background-dark': filterOption === selectedFilter(),
                        '!text-malou-color-chart-pink--accent': filterOption === SocialPostsListFilter.ERROR,
                    }"
                    (click)="selectFilter(filterOption)">
                    <div>{{ filterOption | enumTranslate: 'social_posts_list_filter' }}</div>
                    @if (count && filterOption !== SocialPostsListFilter.ALL) {
                        <div class="italic">({{ count }})</div>
                    }
                </div>
            }
        </div>

        <div class="flex items-center gap-x-1">
            <button class="malou-btn-flat" mat-button (click)="setIsSelecting(true)">
                {{ 'common.select' | translate }}
            </button>

            <app-create-social-post-menu-button
                (createPost)="onCreatePost()"
                (createReelOrTikTok)="onCreateReelOrTikTok()"></app-create-social-post-menu-button>
        </div>
    }
</div>

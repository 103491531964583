import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ImpressionsInsightsComponent } from ':modules/statistics/seo/keyword-search-impressions/impressions-insights/impressions-insights.component';
import { TopKeywordSearchImpressionsComponent } from ':modules/statistics/seo/keyword-search-impressions/top-keyword-search-impressions/top-keyword-search-impressions.component';

@Component({
    selector: 'app-keyword-search-impressions',
    standalone: true,
    imports: [ImpressionsInsightsComponent, TopKeywordSearchImpressionsComponent],
    templateUrl: './keyword-search-impressions.component.html',
    styleUrl: './keyword-search-impressions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordSearchImpressionsComponent {}

import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { MapstrCtaButtonType } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { malouChartColorMapstr } from ':shared/helpers';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-mapstr-preview',
    templateUrl: './mapstr-preview.component.html',
    styleUrls: ['./mapstr-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, TranslateModule, ApplySelfPurePipe, EnumTranslatePipe, ImagePathResolverPipe],
})
export class MapstrPreviewComponent {
    readonly title = input<string>('');
    readonly text = input<string>('');
    readonly media = input<EditionMedia | undefined>();
    readonly date = input<Date | undefined>();
    readonly callToActionType = input<MapstrCtaButtonType | undefined>();

    private readonly _restaurantsService = inject(RestaurantsService);

    readonly currentRestaurant = this._restaurantsService.currentRestaurant;
    readonly malouChartColorMapstr = malouChartColorMapstr;

    readonly formattedDate = computed((): string => {
        const date = this.date();
        if (!date) {
            return '';
        }
        const lang = LocalStorage.getLang();
        return DateTime.fromJSDate(date).setLocale(lang).toLocaleString(DateTime.DATE_MED);
    });

    readonly formattedText = computed(() => this.text().replace(/\n/g, '<br />'));
}
